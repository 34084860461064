var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "invoice-content" }, [
      !_vm.customization
        ? _c("div", [
            _vm.isOfficial
              ? _c("img", {
                  staticClass: "invoice-img",
                  attrs: {
                    src: "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/qrcode_for_gh_cc017f82c39a_258.jpg",
                    alt: "",
                  },
                })
              : _vm._e(),
            _c("img", {
              staticClass: "invoice-img",
              attrs: {
                src: require("@/assets/result/no-invoice.png"),
                alt: "",
              },
            }),
            _vm.$route.query.from == "lvat"
              ? _c("div", { staticClass: "invoice-text" }, [
                  _vm._v("授权成功，数据正在努力采集中，请耐心等待！"),
                ])
              : _c("div", { staticClass: "invoice-text" }, [
                  _vm._v("检测报告生成需T+1个工作日完成，请耐心等待"),
                ]),
          ])
        : _c("div", [
            _vm.$route.query.from == "lvat"
              ? _c("div", { staticClass: "invoice-text" }, [
                  _vm._v("授权成功，数据正在努力采集中，请耐心等待！"),
                ])
              : _c("div", { staticClass: "invoice-text" }, [
                  _vm._v("检测报告生成需T+1个工作日完成，请耐心等待"),
                ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }